<template>
  <div
    class="table-seat-gift sprite-gifts"
    :class="`${giftId}`"
    :id="`gift-${from}-${giftId}-${to}`"
    :ref="`gift-${from}-${giftId}-${to}`"
  ></div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "GlobalGift",
  props: {
    giftId: {
      type: String,
      required: true,
    },
    from: {
      type: Number,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
    sendPlayerId: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
      getActiveRoomState: "service/getActiveRoomState",
      getAnimations: "gift/getAnimations",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
    }),
    animationIsMe() {
      return this.sendPlayerId === this.user.id;
    },
    getComputedIndex() {
      if (!this.nAmISitting) return this.$props.index;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (this.$props.index + shiftValue) % 9;
    },
    data() {
      return this.nGetSeats[this.$props.index];
    },
  },
  methods: {
    getMethodIndex(number) {
      if (!this.nAmISitting) return number;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (number + shiftValue) % 9;
    },
    giftAnimationCompleted() {
      this.$store.commit("gift/giftAnimationCompleted", {
        from: this.from,
        to: this.to,
      });
    },
  },
  mounted() {
    const targetEl = document.getElementsByClassName(`gift-${this.to}`);
    const fromEl = document.getElementsByClassName(`gift-${this.data.id}`);

    if (!(targetEl && targetEl.length > 0) || !(fromEl && fromEl.length > 0)) {
      return;
    }

    let coorXDiff = 0;

    if (fromEl[0].offsetLeft > targetEl[0].offsetLeft) {
      coorXDiff = targetEl[0].offsetLeft - fromEl[0].offsetLeft;
    } else {
      coorXDiff = targetEl[0].offsetLeft - fromEl[0].offsetLeft;
    }

    let coorYDiff = 0;
    if (fromEl[0].offsetTop > targetEl[0].offsetTop) {
      coorYDiff = targetEl[0].offsetTop - fromEl[0].offsetTop;
    } else {
      coorYDiff = targetEl[0].offsetTop - fromEl[0].offsetTop;
    }

    this.$animejs({
      targets: `#gift-${this.from}-${this.giftId}-${this.to}`,
      translateX: coorXDiff,
      translateY: coorYDiff,
      duration: 1000,
      easing: "linear",
      complete: this.giftAnimationCompleted,
    });
  },
};
</script>

<style lang="scss">
.table-seat-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  .vue-sprite {
    canvas {
      z-index: 100 !important;
    }
  }
}
</style>
